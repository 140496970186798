function useTransportTimeMinimum(user: any) {
    const transportTimeMinimum = (): number | undefined => {
        return user?.account?.transportTimeMinimum
    }

    return {
        transportTimeMinimum,
    };
}

export default useTransportTimeMinimum;